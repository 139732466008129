// ContactUs.js
import "./Contact.css";
import React from "react";
import { socialMedia, socialMediaData } from "../../components/SocialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactUs() {
  return (
    <div className="contact-main">
      <h1 className="contact-heading">Contact Us</h1>
      <div className="contact-gps">
        <iframe
          className="contact-map"
          title="gps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117763.69288410929!2d75.78144900047096!3d22.723951637715192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728034017029!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
        <div className="contact-gps-text">
          <h1 className="contact-gps-heading">
            HEADQUARTERS <br /> Indore, M.P
          </h1>
          {/* <p className='contact-gps-address'>State Highway 12A Jhanjeri, Sahibzada Ajit Singh Nagar, Punjab 140307</p> */}
          <div className="contact-us-icons-cgc">
            {Object.entries(socialMedia).map(([platform, link], index) => {
              const socialMediaIcon = socialMediaData[platform];
              return (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="logos" icon={socialMediaIcon} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
