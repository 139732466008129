function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    "../../assets/Partners",
    false,
    /\.(png|jpe?g|JPG|webp|jfif)$/
  )
);
console.log(images.length);
const noimg = images.length;

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: noimg,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 5,
  },
};

export const productData = images.map((image, index) => {
  const fileNameWithExtension = image.default
    ? image.default.split("/").pop()
    : image.split("/").pop();
  const [fileName, extension] = fileNameWithExtension.split(".");
  const fullName = [fileName, extension].join(".");

  console.log(fullName);

  return {
    id: index + 1,
    imageurl: image.default || image,
    url: `https://${fullName}`,
  };
});

export const Event_Data = [
  {
    name: "Web X",
    desc: "Custom website development services to meet your specific needs, whether it's a corporate site, event page, or e-commerce platform. Our expert team ensures a seamless, user-friendly experience with responsive design, SEO optimization, and ongoing support to elevate your online presence.",
    btn: "/monochrome",
  },
  {
    name: "Event X",
    desc: "An automated event registration system with barcode and facial recognition technologies. We provide an online registration website and onsite ID cards or bands with custom barcodes for seamless check-ins, food and beverage payments, and other services. Enhance your event experience with our cutting-edge automation solutions.",
    btn: "/pixelperfect",
  },
  {
    name: "Face X",
    desc: "It Enhances traditional attendance systems with real-time facial recognition technology, enabling the simultaneous recognition and attendance marking of multiple students. This efficient and seamless solution revolutionizes attendance management, providing accuracy and convenience.",
    btn: "/encode",
  },
];
