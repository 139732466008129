import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faInstagram,
  faFacebook,
  faDiscord,
  faTwitter,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export const socialMediaData = {
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  discord: faDiscord,
  reddit: faReddit,
  linkedin: faLinkedin,
  github: faGithub,
  email: faEnvelope,
  website: faGlobe,
};

export const socialMedia = {
  // github:"lalallal",
  linkedin: "https://www.linkedin.com/company/inditechin/",
  instagram: "https://www.instagram.com/inditech_in/",
  // facebook: "https://www.facebook.com/",
  // twitter:"https://twitter.com/karann__25",
  email: "mailto:karan25.pvt@gmail.com",
  // website:"www.inditech.in"
};
