// Members.js

import React,{useState,useEffect} from 'react';
import './codeplayground.css';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-vibrant_ink';
import Sk from 'skulpt'; // python executer



const CodePlayground = ({lang}) => {
    const [pythonCode, setPythonCode] = useState('');
    const [output, setOutput] = useState('');
    const [error, setError] = useState('');
    

    const executePython = () => {
        setError(null)
        let capturedOutput = '';
        Sk.configure({
            output: (text) => {
                capturedOutput += `${text}`;
            },
            __future__: Sk.python3 // Ensure Python 3 syntax
        });
        Sk.misceval.asyncToPromise(() => Sk.importMainWithBody("<stdin>", false, pythonCode, true))
            .then(() => {
                console.log("Output:\n",capturedOutput); // Log the captured output
                setOutput(capturedOutput); // Update the output state or perform other actions
            })
            .catch(error => {
                console.error("Error executing Python code:", error);
                setError("Error executing Python code:", error);
            });
    };

    return (
        <div className='code-playground'>
            <div className="code-editor-top-container">
                <div className='code-editor-top-left-container'>
                    <h1 className='code-editor-top-info-text2'>Code Playground</h1>
                    <div className='code-editor-btn-container'>
                        <button onClick={executePython} className="run-btn">
                            <p>Run</p>
                        </button>
                    </div>
                </div>
                <h1 className='code-editor-top-info-text'>Output</h1>
            </div>
            
            <div className="code-editor-container">
                <div className='code-editor-core-container'>
                    <AceEditor
                        mode={lang} // Set the mode to Python, change to "c_cpp" for C/C++
                        theme="vibrant_ink" // Set the theme, you can change it to another theme if you prefer
                        value={pythonCode}
                        onChange={newcode => setPythonCode(newcode)}
                        name="code-editor"
                        editorProps={{ $blockScrolling: true }}
                        style={{ width: '100%', height: '100%'}}
                        fontSize={18}
                    />
                </div>
                <div className='code-editor-core-container output-container'>
                    <AceEditor
                    mode={lang} // Set the mode to Python, change to "c_cpp" for C/C++
                    theme="vibrant_ink" // Set the theme, you can change it to another theme if you prefer
                    value={error? (error):(output)}
                    name="code-output"
                    editorProps={{ $blockScrolling: true, showGutter: false }}
                    style={{ width: '100%', height: '100%'}}
                    readOnly={true}
                    showGutter= {false}
                    fontSize={18}
                    />
                </div>
            </div>
            
        </div>
    );
}

export default CodePlayground;
