import React from "react";
import "./Gallery.css";
import Carousel from "react-multi-carousel";

const About = (productData) => {
  console.log(productData);
  const images = productData.data.map((item) => (
    <a href={item.url} alt="" target="_blank" rel="noreferrer">
      <div className="gallery-card">
        <img className="gallery-product-image" src={item.imageurl} alt="" />
      </div>
    </a>
  ));

  return (
    <div className="gallery-main">
      <div className="gallery-core">
        <div className="gallery-Carousel">
          <Carousel
            showDots={false}
            responsive={productData.res}
            autoPlay={true}
            autoPlaySpeed={5000}
            infinite={true}
            arrows={false}
          >
            {images}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default About;
