import React from 'react'
import './footer.css';
import { Menu } from '../Menu/Menu';
import inditechlogo from '../../assets/Core/logo.png';
import { socialMedia,socialMediaData} from '../SocialMedia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <div className='footer-main'>
      <div className='footer-vertical-core'>
        <div className='footer-vertical-container'>
          <img className='footer-logo' src={inditechlogo} alt=''/>
          <div className='footer-info-container'>
            <h2 className='footer-vertical-heading'>Address</h2>
            <p>FF 14-15, Scheme no.54, Vijay Nagar,<br/>Indore, Madhya Pradesh - 452010</p>
            <iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.309379634668!2d75.88141877601878!3d22.753897079362982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302a69398d511%3A0x6f8e5b8453689daa!2sShekhar%20Residency!5e0!3m2!1sen!2sin!4v1714413108066!5m2!1sen!2sin" width="100%" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        {/* <div className='footer-vertical-container'>
          <div className='footer-info-container'>
              <h2 className='footer-vertical-heading'>Services</h2>
              <Menu/>
          </div>
        </div> */}

        <div className='footer-vertical-container'>
          <div className='footer-info-container'>
            <h2 className='footer-vertical-heading'>Quick Links</h2>
            <Menu/>
            <div className='footer-socialedia-icon-container'>
              {Object.entries(socialMedia).map(([platform, link], index) => {
                const socialMediaIcon = socialMediaData[platform];
                return (
                    <a key={index} href={link} target='_blank' rel="noopener noreferrer" >
                        <FontAwesomeIcon className="logos" icon={socialMediaIcon} />
                    </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <hr className='footer-hr' />
      <p className='footer-bottom-text'>© 2024 Copyrights by Inditech. All Rights Reserved. Developed by Karan Kumar Agrawal</p>
    </div>
      
    
    
  )
}

export default Footer